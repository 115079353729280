<template>
<div class="rec-wrap">
  <div class="img-wrap">
    <!-- <img src="@/assets/images/myFavors/ddd.png"> -->
    <el-carousel class="banner" v-if="list.length" height="527px" arrow="never" indicator-position="none">
      <el-carousel-item v-for="item in list" :key="item.imgId">
        <img class="img" :src="item.imgUrl">
      </el-carousel-item>
    </el-carousel>
    <div class="team-wrap" v-if="false">
      <div class="team-card">
          <h3>招贤纳士</h3>
          <div>
            <h5>众惠的管理团队</h5>
            <p>汇聚保险、银行、证券、信托、基金等金融领域精英人才，高管团队和核心业务团队从业背景深厚，实战经验丰富。</p>
          </div>
          <div>
            <h5>众惠的风控团队</h5>
            <p>拥有一支高素质专业化的合规风控团队，通过建立全面的风险管控体系，深化调整管理架构，规范风险管理流程。</p>
          </div>
          <div>
            <h5>众惠的产品团队</h5>
            <p>具备良好的金融市场敏感度，对各种金融工具的运作机制有深刻的理解，拥有丰富的金融机构渠道资源。</p>
          </div>
        </div>
    </div>
  </div>
  <div class="info" v-html="content">
      <!-- <h3>社会招聘</h3>
      <p>
        1.根据公司战略和金融机构客户的需求，负责公司场外通（机构客户一站式场外基金投研、交易）平台的相关产品线工作；<br>
        2.与BD协同调研对接银行、非银金融机构相关部门（研究、投资交易、运营、估值）关于基金投前、中、后环节业务需求；<br> 
        3.撰写产品需求文档，并与投资研究、UI设计、研发等配合，高效率、高质量地完成项目； <br>
        4.分析竞品和行业相关产品，对产品进行持续迭代，确保商业价值和用户体验的不断优化下，不断打磨形成超越竞争对手的产品；<br>
        5.协助销售部门完成售前支持功工作，包括业务路演、系统对接路演、业务系统述标等。
      </p> -->
    </div>
  <!-- <div class="info">
      <h3>任职要求</h3>
      <p>
        1.本科及以上学历，金融工程、经济学、计算机相关专业优先； <br>
        2.具备证券或基金从业资格证，有3年及以上基金交易或投资研究相关的系统项目经验；<br>
        3.具备较强的分析、归纳和总结能力，条例清晰，思维严谨；<br>
        4.有很强的学习能力，抗压能力。
      </p>
    </div>
    <div class="info">
      <h3>优先条件</h3>
      <p>
        1.具备to B业务平台产品规划能力，对行业发展有自己的见解；<br>
      </p>
    </div>
    <div class="info">
      <p class="email-p">
        众惠基金期待您的加入，有意者请提供简历至邮箱：<br>
        <span>business@hyzhfund.com</span>
      </p>
    </div> -->
</div>
</template>

<script>
import {
  getDicts
} from '@/api/dict.js'
import {
  getImgs
} from '@/api/other/index.js'
import {
  findRecruit
} from '@/api/myAcc/index.js'
export default {
  data() {
    return {
      list: [],
      content: '',
    }
  },
  created() {
    getDicts('sys_img_user_site').then(result => {
      let imgUseSite = (result.data.find(m => m.dictLabel === 'web端招贤纳士轮播') || {}).dictValue
      if (imgUseSite) {
        getImgs('1', imgUseSite).then(imgResult => {
          this.list = imgResult.data
        })
      }
    })
    findRecruit().then(res => {
      const regex = new RegExp('<img', 'gi')
      const content = res.data.content.replace(regex, `<img style="max-width: 100%; height: auto"`) || '';
      // this.content = res.data.content;
      this.content = content;
    })
  }
}
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');

.rec-wrap {
  overflow: hidden;
  width: 100%;
  padding-bottom: 100px;

  .img-wrap {
    width: 1200px;
    margin: auto;
    height: 527px;
    position: relative;

    .banner {
      margin-left: -360px;
      width: 1920px;
      .img {
        display: block;
        margin: auto;
      }
    }

    .team-wrap {
      width: 1200px;
      position: absolute;
      height: 420px;
      bottom: 0;
      left: calc(50% - 600px);
      z-index: 99;

      .team-card {
        width: 517px;
        height: 420px;
        background: #fff;
        border-radius: 3.75521px 3.75521px 0px 0px;
        padding: 26px 32px;

        &>h3 {
          width: 218.55px;
          font-weight: bold;
          font-size: 36px;
          color: #25293D;
          padding-bottom: 26px;
          border-bottom: 6px solid #5999ED;
          opacity: 0.8;
        }

        &>div {
          width: 100%;
          margin-top: 26px;

          &>h5 {
            font-weight: bold;
            font-size: 16px;
            color: #556881;
          }

          p {
            font-size: 12px;
            color: #556881;
            margin-top: 14px;
          }
        }
      }
    }

  }

  .info {
    width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 1.8em;

    // h3 {
    //   font-weight: normal;
    //   font-size: 20px;
    //   color: #1F1F1F;
    // }
    // p {
    //   margin-top: 14px;
    //   font-size: 14px;
    //   color: #556881;
    //   line-height: 24px;
    // }
    // .email-p span {
    //   font-size: 18px;
    //   color: #D7B381;
    // }
  }

  .info>img {
    max-width: 100%;
  }
}
</style>
